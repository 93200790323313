export const styles = {
    content: {
       position: 'relative',
       
      
       borderRadius: 5,
       justifyContent: 'center',
    },
    botton: {
        backgroundColor: 'white', 
        marginBottom: 10, 
        width: 190,
        height: 50,
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: 14
    },
    dropdown: {
        position: 'absolute',
        left: -33,
        botton: 100,
        width: 190,
        backgroundColor: 'white',
        padding: 2,
        marginBottom: 10,
        borderRadius: 4,
        zIndex: 999
    }, 
    dropdownItem: {
        padding: 10,
        borderColor: '#eee',
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 999
    },
    icons: {
        marginRight: 10,
        width: 30,
        height: 30,
        marginHorizontal: 10,
    },
    iconsSmall: {
        width: 18,
        height: 18,
        marginHorizontal: 10,
    },
    text: {
        color: 'black',
        fontSize: 20
    }
};
