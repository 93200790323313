import React, {useState, useEffect} from 'react';
import LayoutContent from '../../../../components/layouts/content2';
import Margin from '../../../../components/shared/margin';
import {styles} from './styles'; 
import ChangeLang from '../../../../components/changeLang';
import { useSelector } from 'react-redux';
import ItemCategorie from '../../../../components/layouts/content2/itemCategorie';
import { constants } from '../../../../config/constants';
import './style.css';
const Index = () => {
  const [categories, setCategories] = useState([]);

  const {
    allmenu
  } = useSelector((state) => state.initLoad);


  useEffect(()=>{
    setCategories(allmenu.data);
  }, [allmenu]);

  return (
    <LayoutContent showBack={false} showFooterBack={false}>
      <div className="scroll-view" style={{ zIndex: -1, }} nestedScrollEnabled={true}>
        <div className='content-home'>
          <Margin top={20}/>

          <center>
            <div className='content-categotie-list' style={{
              display: 'flex', flexWrap: 'wrap',
              justifyContent: 'space-between', width: '100%',
              alignSelf: 'center',
              
            }}>
            {categories.map((item, key)=>{
              return <div className='item-categorie-content' key={key} style={{
                  width: '49%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                borderRadius: 5,
                padding: 1,
                  marginBottom: 15
                }}>
                    <ItemCategorie item={item} />
                </div>
            })}
          </div>
          </center>
          <br />


          <div style={{}}>
            <img src={require('./assets/banner1.png')} style={{
              width: '100%',
              
            }} />
          </div>
            <br />

           <div style={{}}>
            <img src={require('./assets/banner2.png')} style={{
              width: '100%',
              
            }} />
          </div>
            <br />

           <div style={{}}>
            <img src={require('./assets/banner3.png')} style={{
              width: '100%',
              
            }} />
          </div>


          <Margin top={20}/>
        </div>
        <Margin top={100} />
      </div>
    </LayoutContent>
  );
};

export default Index;
