import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import Margin from '../../../components/shared/margin';
import Head from '../../../components/shared/head';
import {commonStyles as stylesCommon} from '../../../styles/common';
import {useSelector} from 'react-redux';
import LayoutContent from '../../../components/layouts/content2';
import { styles } from './styles';
import {useNavigate, useLocation} from 'react-router-dom';


function Index() {
    const data = useLocation().state;
    const urlSegments = window.location.pathname.split('/');
    const categorie_id = urlSegments[urlSegments.length - 1];

    const navigate = useNavigate();
    const {allmenu} = useSelector((state) => state.initLoad);
    
    const commonStyles  = stylesCommon(); 
    
    const {t, i18n} = useTranslation();
    const langEng = i18n.language === 'en' ?? false;
    const [menu, setMenu] = useState([]);
    const [titleCategorie, setIitleCategorie] = useState('');

    
    return (
        <LayoutContent>
            <div style={styles.content}>
                <Head title={titleCategorie} />
                <Margin top={15} />
                <div style={{...{paddingInline: 20, position: 'relative'}}}>
                    <img  width='100%' src={require('./assets/happy.jpg') } />
                  

                </div>
            </div>
            <Margin top={15} />
        </LayoutContent>
    );
}

export default Index;