
import themes from '../../../../themes';

export const styles = {
    contentMenu: {
        flex: 1,
        borderWidth: 1,
        backgroundColor: 'black'
    },

    text: { 
        textAlign: 'center', 
        fontSize: 40, 
        color: 'white' ,
        fontWeight: 'bold',
    },
    textDescription: {
        fontSize: 20,
    },
    image: {
        width: '100%',
       
        resizeMode: 'cover',
        borderRadius: 8,
    },

    imageDrink: {
        width: '100%',
        height: 818,
        resizeMode: 'cover',
        borderRadius: 5,
       
    },
    contentTags: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    contentPrice: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        backgroundColor: '#000',
        width: 172,
        height: 48,
        textAlign: 'center',
        borderRadius: 27,
        marginTop: 20
    },
    contentIconTags: { 
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    imageTag: {
        width: 40,
        height: 40,
        resizeMode: 'cover',
        marginHorizontal: 4,
    },
    modalQr: {
        flex: 1,
        zIndex: 999,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        width: '110%',
        height: '100%',
    },
    scrollview: {
        marginTop: 20,
        padding: '0 5px',
        zIndex: -1,
    },
    contentDescription: {
        justifyContent: 'space-between'
    },
    contentDescriptionItem: {
        width: '48%'
    },
    contentDescriptionItemTitle: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 5
    },
    contenTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
     
         padding: 20
    },
    imageRecommended: {
        width: '100%',
        height: 300,
    },

    backgroundVideo: {
        width: '100%',
        height: 394,
        borderRadius: 10,
        zIndex: -1
    },

    backButtonRed: {
        borderRadius: 5,
        textAlign: 'center',
        padding: 10,
        paddingVertical: 15,
        backgroundColor: themes.colors.primary,
    },
    textBack: {
        color:  themes.colors.white,
        alignSelf: 'center',
        fontSize: 22,
        textTransform: 'uppercase',
        margin: 6
    },
    textBackRed: {
        color:  themes.colors.white,
        alignSelf: 'center',
        fontSize: 20
    },


    backButtonContent: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20
        
    },
    backButton: {
        width: 90,
        textAlign: 'center',
        paddingVertical: 15,
        backgroundColor: themes.colors.primary,
        fontSize: '16px', 
        color: '#ffffff', 
        borderRadius: '27px', 
        cursor: 'pointer', 
        border: 'none', 
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        height: 36,
        
        
    },

}
