
import themes from '../../../themes';
export const stylesLayout = (modeDark) => {

  return {
    container: {
      flex: 1,
      backgroundColor: themes.colors.white,
      window: '100%'
    },
    content: {
      
    },
    contentNav: {
      paddingTop: 20,
      width: '25%',
      padding: '15px', 
      backgroundColor: themes.colors.grayDark,
    },
    
    contentChild: {
     
    
     

    },
    icons: {
      width: 30,
      height: 30,
    },
    contentIcons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    backButtonContent: {
      position: 'absolute',
      bottom: 20,
      width: 161,
      height: 54,
      left: 20,
      textAlign: 'center',

    },
    backButton: {
      borderRadius: 5,
      textAlign: 'center',
      padding: 10,
      backgroundColor: themes.colors.white
    },
    textBack: {
      color: themes.colors.black,
      alignSelf: 'center',
    },

    contentIconsSocials: {
      flexDirection: 'row',
    },
    iconSocial: {
      width: 38,
      height: 38,
    },

    contentLang: {
      display: "flex",
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    logo: {
      width: '100%',
      height: 141,
      justifyContent: 'center',
      objectFit: 'contain',  
    },
    contentChangeTheme: {
      backgroundColor: '#eee',
      borderRadius: 5,
      width: 600,
      height: 220,
      position: 'absolute',
      bottom: -230,
      left: -560,
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      paddingTop: 10,
      zIndex: 999,
    },
    contentTheme: {
      position: 'absolute',
      right: 10,
      top: 10
    },
    imagetheme: {
      width: 150,
      height: 180,
      objectFit: 'contain'
    },
    btnTheme: {
      //backgroundColor: 'blue'
    },
    textTheme: {
      marginTop: 5,
      color: 'black',
      textAlign: 'center'
    },

    iconMenuMobile: {
      position: 'fixed',
      top: 20,
      left: 20,
      borderRadius: 14,
      backgroundColor: '#000',
      padding: 20,
      zIndex: 999,
      border: 0,
    }
  };
};
