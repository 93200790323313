import React from 'react';
import { Link } from 'react-router-dom';
import {commonStyles as stylesGeneral} from '../../../../styles/common';
import {useSelector} from 'react-redux';
import Margin from '../../../../components/shared/margin';
import {useTranslation} from 'react-i18next';
import translate from '../../../..//helpers/translate';
import {styles} from './styles';

import './style.css';
function Index({item}) {
    
    const {i18n} = useTranslation();
    const langEng = i18n.language === 'en' ?? false;
    const {modeDark: darkMode} = useSelector((state) => state.modeDark);
    const urlSegments = window.location.pathname.split('/');
    const hash = urlSegments[urlSegments.length - 1];

    const commonStyles = stylesGeneral(darkMode);

    return (
        <Link
            className='item-categorie item-categorie-home' 
            to={item.id === 153 ? '/happyhour' : item.id === 111 ? '/drinks': item.id === 112 ? '/special' :`/MenuList/${hash}/${item.id}`}
            state={{ categorie: item }}
            style={{
                ...styles.itemcategorie,
                ...(darkMode === 'dark' ? styles.itemcategorieDark : styles.itemcategorieLight),
                ...(item.icon === '' ? { justifyContent: 'center' } : {}),
                ...{
                    height: 170,
                    width: "100%",
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'center', borderRadius: 21, padding: 10,
                    backgroundImage: `url('https://tumenu.online/images/categories/icons/${item.icon}')`
                    
                }
            }}
        >

            <div className='overlay'></div>

            <p  style={{...commonStyles.txt_p_regular, ...styles.label, margin: 0, color: "#fff", fontSize: 25}}>
                {
                    langEng ? item.name : translate(i18n.language, item.language, item.name)
                }
            </p>
          
        </Link>
    );
}

export default Index;