import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import translate from '../../../../helpers/translate';
import translateMenu from '../../../../helpers/translateMenu';
import Margin from '../../../../components/shared/margin';
import {constants} from '../../../../config/constants';
import {commonStyles as stylesCommon} from '../../../../styles/common';
import {Tracking} from '../../../../hooks/useTracking';
import {styles} from './styles';
import { useSelector } from 'react-redux';

function Index({
    menu,
    allDish
}) {
    const {i18n} = useTranslation();
    const modeDark = useSelector((state) => state.modeDark).modeDark;

    const commonStyles  = stylesCommon(modeDark); 
    const langEng = i18n.language === 'en' ?? false;
   
    const urlSegments = window.location.pathname.split('/');
    const hash = urlSegments[urlSegments.length - 1];

    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    useEffect(()=>{
        const data = {
            menu_id: menu.id,
            type: 'impression',
        }
      //  Tracking(data);
    }, []);


    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <p>{str}</p>);
        
        return newText;
      }

    

    return (
         <>
            <Link
                className='item-categorie'
            to={`/MenuDetail/${hash}`}
            state={{ menu, allDish }}
               style={styles.item}
            >
                    <div style={styles.contentItemImage}>

                    {imageError ? (
                        <img 
                            style={styles.imageMenu}
                            src={require('../../../../assets/images/placeholder.jpg')}alt="Placeholder" />
                    ) : (
                        <img
                                style={styles.imageMenu}
                                 className='imagelist'
                            src={`${constants.pathImagesMenu}/${menu.image}`} onError={handleImageError} />
                    )}


                        
                    </div>
                    <div style={styles.contentItemDescription}>
                        <div style={styles.contentTitle}>
                            <p style={{...commonStyles.txt_p_medium, ...{textAlign: 'center', color: '#000', margin: 10, WebkitLineClamp: 1,  display: '-webkit-box', WebkitBoxOrient: 'vertical',overflow: 'hidden',}}}>
                                {langEng ? menu.name : translate(i18n.language, menu.language, menu.name)}
                            </p>
                        </div>
                        <p  style={{...commonStyles.txt_p_regular_light_small, ...styles.description, ...{color: '#000', margin: 10, textAlign: 'center'}}}>
                            {langEng ? 
                                    <NewlineText text={menu.regular_decription.replace(/\\n/g,'\n')}/>
                                : 
                                <NewlineText text={translateMenu(
                                    i18n.language, 
                                    menu.language, 
                                    menu.regular_decription, 
                                    'regular_decription'
                                )}/>
                            }
                        </p>
                        <div style={styles.contentPrice}>
                            <div style={styles.price}>
                                <p style={{...commonStyles.txt_p_regular_light, ...{textAlign: 'center', color: '#000'}}}>${menu.price.toFixed(2)}</p>
                            </div>
                            <div>
                                
                            </div>
                        </div>

                    </div>
                    
                </Link>
        </>
    );
}

export default Index